import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
      { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
      { text: reportHeadData.address, style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]
      // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const search = vm.search
      const allRowsHead = [
        [
          { text: vm.$t('org_pro.service_namel') + ' : ' + (search.service_id ? vm.getColumnName(Store.state.licenseRegistration.commonObj.serviceNamesList, search.service_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.type') + ' : ' + (search.application_type ? vm.getApplicationTypeName(search.application_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.from_date') + ' : ' + (search.from_date ? dateFormat(search.from_date) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('globalTrans.to_date') + ' : ' + (search.to_date ? dateFormat(search.to_date) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('dealer_management.fertilizer_name') + ' : ' + (search.fertilizer_name_id ? vm.getColumnName(Store.state.licenseRegistration.commonObj.fertilizerName, search.fertilizer_name_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          { text: vm.$t('grantDistribution.organization_name') + ' : ' + (search.organization_name ? search.organization_name : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true }
        ],
        [
          { text: vm.$t('globalTrans.mobile') + ' : ' + (search.mobile_no ? search.mobile_no : vm.$t('globalTrans.all')), alignment: 'left', style: 'th', bold: true },
          {}
        ]
      ]
      pdfContent.push({
        table: {
          widths: ['50%', '50%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      if (vm.listData.length) {
        const allRows = [
          [
            { text: vm.$t('org_pro.service_namel'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.application_id'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.new'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('step_assign.renew'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('grantDistribution.organization_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('admission_form.register_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.fertilizer_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.brand_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.issue_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('li_step.expire_date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('certify_form.remarks'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        vm.listData.forEach((data, index) => {
          data.data.forEach((val, index1) => {
            const firstRow = []
            if (index1 === 0) {
              firstRow.push({ text: vm.getColumnName(Store.state.licenseRegistration.commonObj.serviceNamesList, val.service_id), alignment: 'center', style: 'td', rowSpan: data.data.length })
            } else {
              firstRow.push({})
            }
            let orgName = ''
            if (val.service_id === 5) {
              orgName = i18n.locale === 'bn' ? val.im_org_name_bn : val.im_org_name
            } else if (val.service_id === 6) {
              orgName = i18n.locale === 'bn' ? val.pr_org_name_bn : val.pr_org_name
            } else {
              orgName = i18n.locale === 'bn' ? val.di_org_name_bn : val.di_org_name
            }
            let prefix = ''
            if (val.service_id === 5) {
              prefix = 'IMP_'
            } else if (val.service_id === 6) {
              prefix = 'M_'
            } else {
              prefix = 'S_'
            }
            prefix = prefix + val.generate_id
            let brand = ''
            if (val.service_id === 5) {
              brand = val.im_brand
            } else if (val.service_id === 6) {
              brand = val.pr_brand
            } else {
              brand = val.di_brand
            }
            firstRow.push(
              { text: val.application_id, alignment: 'center', style: 'td' },
              { text: val.type === 1 ? vm.$t('globalTrans.new') : '', alignment: 'center', style: 'td' },
              { text: val.type !== 1 ? vm.$t('globalTrans.renew') : '', alignment: 'center', style: 'td' },
              { text: orgName, alignment: 'center', style: 'td' },
              { text: prefix, alignment: 'center', style: 'td' },
              { text: vm.getColumnName(Store.state.licenseRegistration.commonObj.fertilizerName, val.fertilizer_name_id), alignment: 'center', style: 'td' },
              { text: brand, alignment: 'center', style: 'td' },
              { text: dateFormat(val.issue_date), alignment: 'center', style: 'td' },
              { text: dateFormat(val.expire_date), alignment: 'center', style: 'td' },
              {}
            )
            allRows.push(firstRow)
          })
          allRows.push(
            [
              { text: vm.$t('sitePreference.total'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$n(vm.totalSumTypeService(data.data, 1) + vm.totalSumTypeService(data.data, 2), { useGrouping: false }), style: 'th', alignment: 'center', bold: true },
              { text: vm.$n(vm.totalSumTypeService(data.data, 1), { useGrouping: false }), style: 'th', alignment: 'center', bold: true },
              { text: vm.$n(vm.totalSumTypeService(data.data, 1), { useGrouping: false }), style: 'th', alignment: 'center', bold: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {}
            ]
          )
        })
        allRows.push(
          [
            { text: vm.$t('bsri_demandDIS.in_total'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(vm.totalSumType(1) + vm.totalSumType(2), { useGrouping: false }), style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(vm.totalSumType(1), { useGrouping: false }), style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(vm.totalSumType(2), { useGrouping: false }), style: 'th', alignment: 'center', bold: true },
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ]
        )
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '8%', '8%', '11%', '12%', '9%', '8%', '8%', '8%', '8%', '10%'],
            // widths: '*',
            body: allRows
          }
        })
      } else {
        pdfContent.push({ text: vm.$t('globalTrans.noDataFound'), style: 'fertilizer', alignment: 'center' })
      }
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 10 : 9,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 9 : 7,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 0]
        },
        org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        },
        header1: {
          fontSize: 15,
          margin: [5, 5, 5, 5]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
