<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('li_step.document_issue_wise_monthly_and_yearly_report') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Service Name" vid="service_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro.service_namel') }}
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.service_id"
                                    :options="serviceNamesList"
                                    id="service_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Application Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.application_type"
                              :options="applicationTypeList"
                              id="application_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="From Date" vid="from_date">
                          <b-form-group
                            :label="$t('globalTrans.from_date')"
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.from_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.from_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="To Date" vid="to_date">
                          <b-form-group
                            :label="$t('globalTrans.to_date')"
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                            {{ $t('globalTrans.to_date') }}
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="search.to_date"
                              placeholder="yyyy-mm-dd"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Fertilizer Name">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="fertilizer_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('dealer_management.fertilizer_name') }}
                            </template>
                            <v-select name="fertilizer_name_id"
                              v-model="search.fertilizer_name_id"
                              label="text"
                              :reduce="item => item.value"
                              :options= fertilizerNamesList
                              :placeholder="$t('globalTrans.select')"
                              :filter-by="myFilter"
                              :state="errors[0] ? false : (valid ? true : null)"
                            />
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6" md="6">
                        <ValidationProvider name="Organization Name" vid="organization_name" >
                          <b-form-group
                            label-for="organization_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('grantDistribution.organization_name') }}
                            </template>
                            <b-form-input
                              plain
                              v-model="search.organization_name"
                              id="organization_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6" md="6">
                        <ValidationProvider name="Mobile" vid="mobile_no" >
                          <b-form-group
                            label-for="mobile_no"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.mobile') }}
                            </template>
                            <b-form-input
                              plain
                              v-model="search.mobile_no"
                              id="mobile_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-if="showData">
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.document_issue_wise_monthly_and_yearly_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" :org-id="search.org_id">
                                        {{ $t('li_step.document_issue_wise_monthly_and_yearly_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('org_pro.service_namel') }}: <strong>{{ search.service_id ? getServiceName(search.service_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.type') }}: <strong>{{ search.application_type ? getApplicationTypeName(search.application_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.from_date') }} : <strong>{{ search.from_date ? dateFormatChange(search.from_date) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.to_date') }} : <strong>{{ search.from_date ? dateFormatChange(search.to_date) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('dealer_management.fertilizer_name') }} : <strong>{{ search.fertilizer_name_id ? getColumnName($store.state.licenseRegistration.commonObj.fertilizerName, search.fertilizer_name_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('grantDistribution.organization_name') }}: <strong>{{ search.organization_name ? search.organization_name : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.mobile') }}: <strong>{{ search.mobile_no ? search.mobile_no : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col md="12" class="table-responsive">
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="listData.length">
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:15%">{{$t('org_pro.service_namel')}}</b-th>
                                            <b-th style="width:10%">{{$t('globalTrans.application_id')}}</b-th>
                                            <b-th style="width:10%">{{$t('step_assign.new')}}</b-th>
                                            <b-th style="width:10%">{{$t('step_assign.renew')}}</b-th>
                                            <b-th style="width:10%">{{$t('grantDistribution.organization_name')}}</b-th>
                                            <b-th style="width:10%">{{$t('admission_form.register_no')}}</b-th>
                                            <b-th style="width:10%">{{$t('li_step.fertilizer_name')}}</b-th>
                                            <b-th style="width:10%">{{$t('li_step.brand_name')}}</b-th>
                                            <b-th style="width:10%">{{$t('li_step.issue_date')}}</b-th>
                                            <b-th style="width:10%">{{$t('li_step.expire_date')}}</b-th>
                                            <b-th style="width:10%">{{$t('certify_form.remarks')}}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <slot v-for="data in listData">
                                            <b-tr v-for="(val, index1) in data.data" :key="index1">
                                              <b-th class="text-center" v-if="index1 === 0" :rowspan="data.data.length">{{ getColumnName($store.state.licenseRegistration.commonObj.serviceNamesList, data.service_id) }}</b-th>
                                              <b-td style="text-align:right !important">{{ val.application_id }}</b-td>
                                              <b-td>
                                                <span class="badge pay-status badge-primary w-100 text-white font-weight-bold" v-if="val.type === 1">
                                                  {{ $t('globalTrans.new') }}
                                                </span>
                                              </b-td>
                                              <b-td>
                                                <span class="badge pay-status badge-success w-100 text-white font-weight-bold" v-if="val.type === 2">
                                                  {{ $t('globalTrans.renew') }}
                                                </span>
                                              </b-td>
                                              <b-td>
                                                <slot v-if="val.service_id === 5">
                                                  {{ ($i18n.locale === 'bn') ? val.im_org_name_bn : val.im_org_name }}
                                                </slot>
                                                <slot v-else-if="val.service_id === 6">
                                                  {{ ($i18n.locale === 'bn') ? val.pr_org_name_bn : val.pr_org_name }}
                                                </slot>
                                                <slot v-else-if="val.service_id === 23">
                                                  {{ ($i18n.locale === 'bn') ? val.di_org_name_bn : val.di_org_name }}
                                                </slot>
                                              </b-td>
                                              <b-td style="text-align:right !important">
                                                <slot v-if="val.service_id === 5">IMP_</slot>
                                                <slot v-else-if="val.service_id === 6"> M_</slot>
                                                <slot v-else-if="val.service_id === 23">S_</slot>
                                                {{ val.generate_id }}
                                              </b-td>
                                              <b-td>
                                                {{ getColumnName($store.state.licenseRegistration.commonObj.fertilizerName, val.fertilizer_name_id) }}
                                              </b-td>
                                              <b-td>
                                                <slot v-if="val.service_id === 5">
                                                  {{ val.im_brand }}
                                                </slot>
                                                <slot v-else-if="val.service_id === 6">
                                                  {{ val.pr_brand }}
                                                </slot>
                                                <slot v-else-if="val.service_id === 23">
                                                  {{ val.di_brand }}
                                                </slot>
                                              </b-td>
                                              <b-td>{{ val.issue_date | dateFormat }}</b-td>
                                              <b-td>{{ val.expire_date | dateFormat }}</b-td>
                                              <b-td></b-td>
                                            </b-tr>
                                            <b-tr :key="'foot'">
                                              <b-td>{{ $t('sitePreference.total') }}</b-td>
                                              <b-td class="text-right">{{ $n(totalSumTypeService(data.data, 1) + totalSumTypeService(data.data, 2), { useGrouping: false }) }}</b-td>
                                              <b-td class="text-right">{{ $n(totalSumTypeService(data.data, 1), { useGrouping: false }) }}</b-td>
                                              <b-td class="text-right">{{ $n(totalSumTypeService(data.data, 2), { useGrouping: false }) }}</b-td>
                                              <b-td class="text-center"></b-td>
                                              <b-td class="text-center"></b-td>
                                              <b-td class="text-center"></b-td>
                                              <b-td class="text-center"></b-td>
                                              <b-td class="text-center"></b-td>
                                              <b-td class="text-center"></b-td>
                                              <b-td class="text-center"></b-td>
                                            </b-tr>
                                          </slot>
                                        </b-tbody>
                                        <b-tfoot>
                                          <b-tr key="foot" v-if="listData.length">
                                            <b-th>{{ $t('bsri_demandDIS.in_total') }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSumType(1) + totalSumType(2), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSumType(1), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right">{{ $n(totalSumType(2), { useGrouping: false }) }}</b-th>
                                            <b-th style="text-align:right"></b-th>
                                            <b-th style="text-align:right"></b-th>
                                            <b-th style="text-align:right"></b-th>
                                            <b-th style="text-align:right"></b-th>
                                            <b-th style="text-align:right"></b-th>
                                            <b-th style="text-align:right"></b-th>
                                            <b-th style="text-align:right"></b-th>
                                          </b-tr>
                                        </b-tfoot>
                                      </b-table-simple>
                                      <template v-else>
                                        <h3 class="text-center text-danger">{{ $t('globalTrans.noDataFound') }}</h3>
                                      </template>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.table thead th, td {
  vertical-align: middle !important;
  text-align: center;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { daeDocumentIssueReport } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import ExportPdf from './export_pdf'
import { helpers } from '@/mixins/helper-functions.js'
import flatpickr from 'flatpickr'
import { dateFormat } from '@/Utils/fliter'

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      search: {
        org_id: 2,
        service_id: 0,
        year: 0,
        from_date: '',
        to_date: '',
        application_type: 0,
        fertilizer_name_id: null,
        organization_name: '',
        mobile_no: '',
        paid_type: 0
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      listData: [],
      grandTotalInfo: {},
      excelColumnBn: {},
      excelColumn: {},
      showData: false,
      threeMonthsAgo: '',
      date_selected: this.$t('step_assign.current_month'),
      previus: this.$t('step_assign.previus'),
      last_tree_month: this.$t('step_assign.last_tree_month'),
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
        return this.$store.state.commonObj.loading
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    serviceNamesList () {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === 2))
    },
    fertilizerNamesList () {
      return this.$store.state.licenseRegistration.commonObj.fertilizerName
    },
    yearList () {
      return helpers.getYearListCurrent({ yearFrom: 2021 })
    },
    monthList () {
      return this.$store.state.commonObj.monthList
    },
    applicationTypeList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ', text_en: 'Reneiw', text_bn: 'রিনিউ' }
      ]
      return list
    },
    paymentTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          text_en: 'Offline',
          text_bn: 'অফলাইন'
        }
      ]
    },
    authOrgId () {
      const authUser = this.$store.state.Auth.authUser
      const activeRoleId = this.$store.state.Auth.activeRoleId
      return activeRoleId !== 1 ? authUser.org_id : 0
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      if (newVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      } else {
        this.serviceNamesList = []
      }
    }
  },
  created () {
    if (this.authOrgId) {
      this.search.org_id = this.authOrgId
    }
  },
  mounted () {
    core.index()
    flatpickr('.datepicker', {})
  },
  methods: {
    dateFormatChange (data) {
      return dateFormat(data)
    },
    totalSumType (field = null) {
      let total = 0
      this.listData.forEach((info, index) => {
        info.data.forEach((info1, index1) => {
          if (field === 1) {
            if (parseInt(info1.type) === 1) {
              total++
            }
          } else {
            if (parseInt(info1.type) !== 1) {
              total++
            }
          }
        })
      })
      return total
    },
    totalSumTypeService (data, field = null) {
      let total = 0
      data.forEach((info1, index1) => {
        if (field === 1) {
          if (parseInt(info1.type) === 1) {
            total++
          }
        } else {
          if (parseInt(info1.type) !== 1) {
            total++
          }
        }
      })
      return total
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    pdfExport () {
      const reportTitle = this.$t('li_step.document_issue_wise_monthly_and_yearly_report')
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
    },
    async loadData () {
      this.showData = true
      this.listData = []
      this.grandTotalInfo = {}
      this.$store.commit('mutateCommonProperties', {
          loading: true
      })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, daeDocumentIssueReport, this.search)
      if (result.success) {
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
        this.listData = result.data
      } else {
        this.listData = []
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
      }
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === orgId))
      return tmpService.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getOrganizationName (id) {
      const obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getServiceName (id) {
      const obj = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getApplicationTypeName (id) {
      const obj = this.applicationTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getPaymentTypeName (id) {
      const obj = this.paymentTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    }
  }
}
</script>
<style >
.table-bordered th{
border: 1px solid #000 !important;
}
</style>
